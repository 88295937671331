/**
* Implement Gatsby's Browser APIs in this file.
*
* See: https://www.gatsbyjs.com/docs/browser-apis/
*/
// You can delete this file if you're not using it
import "././node_modules/bootstrap/dist/css/bootstrap.min.css" // This to be added.
import "././node_modules/bootstrap/dist/js/bootstrap.bundle"
import "./src/style/style.scss";
import "././static/font/all.min.css";
import "././static/font/stylesheet.css";
import "./src/style/style.css";
import "./src/style/responsive.scss";

import ReactDOM from "react-dom/client"

export const onRouteUpdate = ({ location, prevLocation }) => {
    console.log('New pathname', location.pathname);
    console.log('Old pathname', prevLocation ? prevLocation.pathname : null);

    setTimeout(() => {
        console.log('onRouteUpdate scrollTo called');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);

    if (typeof window !== 'undefined') {
        console.log('Window object is available');
    } else {
        console.error('Window object is not available');
    }
};

export const replaceHydrateFunction = () => {

    return (element, container) => {
        const root = ReactDOM.createRoot(container)
        root.render(element)
    }
}
